import { BrowserRouter as Router } from "react-router-dom";
import { ContextApi } from "./Contexts/ContextApi";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import RouterContainer from "./routes/Index";
import TopScroll from "./Common/topScroll";
import SnackbarProviderWrap from "./SnackbarProvider";
import { ThemeProvider, createTheme } from "@material-ui/core";
import "./app.css";

const theme = createTheme({
  palette: {
    primary: {
      main: '#4d7dfa',
    },
  },
});

function App() {
  const { t, i18n } = useTranslation();
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [user, setUser] = useState(null);
  const [adminLoginMsg, setAdminLoginMsg] = useState("");
  return (
    <>
      <ContextApi.Provider
        value={{
          t,
          i18n,
          user,
          setUser,
          setLoggedIn,
          loggedIn,
          adminLoginMsg,
          setAdminLoginMsg,
        }}
      >
        {/* <PersistGate> */}
        <SnackbarProviderWrap>
          <ThemeProvider theme={theme}>
            <Router>
              <TopScroll />
              <RouterContainer />
            </Router>
          </ThemeProvider>
        </SnackbarProviderWrap>
        {/* </PersistGate> */}
      </ContextApi.Provider>
    </>
  );
}

export default App;
