import { Suspense, lazy, useEffect } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { useBoundStore } from "../store/useBoundStore";
import Loader from "../Components/Loader/Loader";

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // const { pathname } = useLocation();
  // const isDashboard = pathname.includes("/app");
  const isDashboard = false;

  return (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );
};

const Dashboard = Loadable(
  lazy(() => import("../Components/AdminPortal/Dashboard"))
);
const AdminjobPosting = Loadable(
  lazy(() => import("../Components/AdminPortal/AdminJobPosting"))
);
const AdminJobOpening = Loadable(
  lazy(() => import("../Components/AdminPortal/AdminJobOpening"))
);
const AdminLayout = Loadable(lazy(() => import("../Layouts/AdminLayout")));
const ViewBulkmailHistory = Loadable(
  lazy(() => import("../Components/AdminPortal/ViewBulkMailHistory"))
);
const MainLayout = Loadable(lazy(() => import("../Layouts/MainLayout")));
const About = Loadable(lazy(() => import("../Pages/About")));
const GlobalCampuses = Loadable(lazy(() => import("../Pages/global_campus")));
const Home = Loadable(lazy(() => import("../Pages/Home")));
const JobDetails = Loadable(lazy(() => import("../Pages/JobDetails")));
const JobPostings = Loadable(lazy(() => import("../Pages/jobs")));
const ProfileFirst = Loadable(lazy(() => import("../Pages/ProfileFirst")));
const ProfileFourth = Loadable(lazy(() => import("../Pages/ProfileFourth")));
const ProfileSecond = Loadable(lazy(() => import("../Pages/ProfileSecond")));
const ProfileThird = Loadable(lazy(() => import("../Pages/ProfileThird")));
const SignIn = Loadable(lazy(() => import("../Pages/sigin")));
const NewSignUp = Loadable(lazy(() => import("../Pages/NewAuth/Login")));
const NewRegister = Loadable(lazy(() => import("../Pages/NewAuth/Register")));
const SignUp = Loadable(lazy(() => import("../Pages/signup")));
const WorkAtHome = Loadable(lazy(() => import("../Pages/wfh")));
const ForgotPassword = Loadable(lazy(() => import("../Pages/ForgotPassword")));
const AdminSignin = Loadable(lazy(() => import("../Pages/AdminSignin")));
const AddJob = Loadable(
  lazy(() => import("../Components/AdminPortal/JobOpeningForm"))
);
const NewAddJob = Loadable(
  lazy(() => import("../Components/AdminPortal/NewJobOpeningForm"))
);
const AppliedStatus = Loadable(
  lazy(() => import("../Components/AdminPortal/JobAppliedStatus"))
);
const AppliedApplicants = Loadable(
  lazy(() => import("../Components/AdminPortal/AppliedApplicants"))
);
const UserDetails = Loadable(
  lazy(() => import("../Components/AdminPortal/AppliedUserDetail"))
);
const AppliedProfile = Loadable(
  lazy(() => import("../Components/AppliedProfile"))
);
const MemberSearch = Loadable(
  lazy(() => import("../Components/AdminPortal/MemberSearch"))
);
const TemplateComponent = Loadable(
  lazy(() => import("../Components/AdminPortal/EmailTemplate"))
);
const AdminCloudCert = Loadable(
  lazy(() => import("../Components/AdminPortal/AdminCloudCrt"))
);
const AdminTrainingClass = Loadable(
  lazy(() => import("../Components/AdminPortal/AdminTrainingClass"))
);
const TrainRegMem = Loadable(
  lazy(() => import("../Components/AdminPortal/TrainRegMem"))
);
const AdminBulkEmail = Loadable(
  lazy(() => import("../Components/AdminPortal/AdminBulkEmail"))
);
const AdminBulkSMS = Loadable(
  lazy(() => import("../Components/AdminPortal/AdminBulkSMS"))
);
const NavigationBar = Loadable(
  lazy(() => import("../Pages/CandidateProfile/NavBar"))
);
const JobApplication = Loadable(lazy(() => import("../Pages/JobApplication")));
const JobApply = Loadable(lazy(() => import("../Components/jobs/JobApply")));
const NewEmailTemp = Loadable(
  lazy(() => import("../Components/AdminPortal/NewEmailTemp"))
);
const SingleEmail = Loadable(
  lazy(() => import("../Components/AdminPortal/SingleEmail"))
);
const SingleSMS = Loadable(
  lazy(() => import("../Components/AdminPortal/SingleSMS"))
);
const AddNewAdmin = Loadable(
  lazy(() => import("../Components/AdminPortal/AddNewAdmin"))
);
const AdminPanel = Loadable(
  lazy(() => import("../Components/AdminPortal/AdminPanel"))
);
const ResetPassword = Loadable(
  lazy(() => import("../Components/ForgotPassword/ResetPassword"))
);
const SettingList = Loadable(
  lazy(() => import("../Components/AdminPortal/SettingList"))
);
const AddStatus = Loadable(
  lazy(() => import("../Components/AdminPortal/AddStatus"))
);
const AddJobStatus = Loadable(
  lazy(() => import("../Components/AdminPortal/AddJobStatus"))
);
const AddDepartment = Loadable(
  lazy(() => import("../Components/AdminPortal/AddDepartment"))
);
const AddJobType = Loadable(
  lazy(() => import("../Components/AdminPortal/AddJobType"))
);
const AddMinExperience = Loadable(
  lazy(() => import("../Components/AdminPortal/AddMinExperience"))
);
const AddLanguage = Loadable(
  lazy(() => import("../Components/AdminPortal/AddLanguage"))
);
const AddJobLocation = Loadable(
  lazy(() => import("../Components/AdminPortal/AddJobLocation"))
);
const AddShiftTiming = Loadable(
  lazy(() => import("../Components/AdminPortal/AddShiftTiming"))
);
const AddSource = Loadable(
  lazy(() => import("../Components/AdminPortal/AddSource"))
);
const EditCandidateDetails = Loadable(
  lazy(() => import("../Components/AdminPortal/EditCandidateDetails"))
);
const AdminResetPassword = Loadable(
  lazy(() => import("../Components/ForgotPassword/AdminResetPassword"))
);
const OfferLetter = Loadable(
  lazy(() => import("../Components/AdminPortal/OfferLetter"))
);
const AdminForgotPassword = Loadable(
  lazy(() => import("../Components/ForgotPassword/AdminForgotPassword"))
);
const AddNewCandidate = Loadable(
  lazy(() => import("../Components/AdminPortal/AddNewCandidate"))
);
const AdmnFrstRstPwd = Loadable(
  lazy(() => import("../Components/ForgotPassword/AdmnFrstRstPwd"))
);
const CandFrstRstPwd = Loadable(
  lazy(() => import("../Components/ForgotPassword/CandFrstRstPwd"))
);
const NewOfferTemplate = Loadable(
  lazy(() => import("../Components/AdminPortal/NewOfferTemplate"))
);
const ModifyOfferLetter = Loadable(
  lazy(() => import("../Components/AppliedProfile/ModifyOfferLetter"))
);
const OfferTemplate = Loadable(
  lazy(() => import("../Components/AdminPortal/OfferTemplate"))
);
const AllReportsList = Loadable(
  lazy(() => import("../Components/AdminPortal/AllReportsList"))
);
const CandPipelnReport = Loadable(
  lazy(() => import("../Components/AdminPortal/CandPipelnReport"))
);
const CandSourceReport = Loadable(
  lazy(() => import("../Components/AdminPortal/CandSourceReport"))
);
const TimetoHireReport = Loadable(
  lazy(() => import("../Components/AdminPortal/TimetoHireReport"))
);
const BulkmailReport = Loadable(
  lazy(() => import("../Components/AdminPortal/BulkmailHistoryReport"))
);
const NonSelectRprt = Loadable(
  lazy(() => import("../Components/AdminPortal/NonSelectRprt"))
);
const OffrAccptRatioRprt = Loadable(
  lazy(() => import("../Components/AdminPortal/OffrAccptRatioRprt"))
);
const ViewOffer = Loadable(lazy(() => import("../Pages/view_offer")));
const ViewOfferAdmin = Loadable(
  lazy(() => import("../Pages/view_offer_admin"))
);
const ImochaReport = Loadable(
  lazy(() => import("../Components/AdminPortal/ImochaReport"))
);
const IMochaSingleTest = Loadable(
  lazy(() => import("../Components/AdminPortal/IMochaSingleTest"))
);
const AllAccessLevels = Loadable(
  lazy(() => import("../Components/AdminPortal/AllAccessLevels"))
);
const AccessLevelAdmin = Loadable(
  lazy(() => import("../Components/AdminPortal/AccessLevelAdmin"))
);
const Calendar = Loadable(
  lazy(() =>
    import("../Components/AdminPortal/Calender/googleCalendar/Calendar")
  )
);
const CreateOfferLatter = Loadable(
  lazy(() => import("../Components/AppliedProfile/CreateOfferLatter"))
);
const MBSeach = Loadable(lazy(() => import("../Components/MemberSearch")));
const EmailTemplate = Loadable(
  lazy(() => import("../Components/AdminPortal/EmailTemplateUpdate"))
);
const AdminCloudCertifications = Loadable(
  lazy(() => import("../Components/AdminPortal/AdminCloudCertifications"))
);
const CandidateProfile = Loadable(
  lazy(() => import("../Pages/CandidateProfile"))
);
const OAuth = Loadable(lazy(() => import("../Pages/OAuth")));

const adminRoutes = [
  {
    path: "Account",
    element: <NavigationBar />,
    // element: <MainLayout />,
    children: [
      {
        path: "Login",
        element: (
          <GoogleOAuthProvider clientId="553379843351-575ut0677hqf8c9g3t4fj4qbpk5sd27a.apps.googleusercontent.com">
            <NewSignUp />
          </GoogleOAuthProvider>
        ),
      },
      {
        path: "Register",
        element: <NewRegister />,
      },
      {
        path: "old-Login",
        element: (
          <GoogleOAuthProvider clientId="553379843351-575ut0677hqf8c9g3t4fj4qbpk5sd27a.apps.googleusercontent.com">
            <SignIn title="Login" />
          </GoogleOAuthProvider>
        ),
      },
      {
        path: "old-Register",
        element: <SignUp title="Register" />,
      },
      {
        path: "ForgotPassword",
        element: <ForgotPassword title="Forgot Password" />,
      },
    ],
  },
  { path: "oauth", element: <OAuth /> },
  {
    path: "/",
    element: <NavigationBar />,
    // element: <MainLayout />,
    children: [
      { path: "/", element: <Home title="Home" /> },

      { path: "About", element: <About title="About" /> },
      { path: "WorkFromHome", element: <WorkAtHome title="Work At Home" /> },
      {
        path: "globalCampus",
        element: <GlobalCampuses title="Global Campuses" />,
      },
      {
        path: "globalCampus/:id",
        element: <GlobalCampuses title="GlobalCampus" />,
      },
      {
        path: "Jobs-posting/:countries",
        element: <JobPostings title="Jobs" />,
      },
      { path: "Jobs-posting", element: <JobPostings title="Jobs" /> },
      {
        path: "Job-description/:id",
        element: <JobDetails title="Job Description" />,
      },
      { path: "OldJobApply/:id", element: <JobApply title="Job Application" /> },
      {
        path: "JobApply/:id",
        element: <JobApplication title="Job Application" />,
      },
      { path: "view_offer/:id", element: <ViewOffer title="View Offer" /> },
    ],
  },
  {
    path: "Profile",
    element: <CandidateProfile />,
  },
  // {
  //   path: "Profile",
  //   element: <MainLayout />,
  //   children: [
  //     {
  //       path: "/Profile",
  //       element: <Navigate to="/Profile/BuildProfile" replace />,
  //     },
  //     {
  //       path: "BuildProfile",
  //       element: <ProfileFirst title="Build Profile" />,
  //     },
  //     {
  //       path: "MyApplications",
  //       element: <ProfileSecond title="My Applications" />,
  //     },
  //     {
  //       path: "WAHCloudCertification",
  //       element: <ProfileThird title="WAH Cloud Certification" />,
  //     },
  //     {
  //       path: "TrainingRegistration",
  //       element: <ProfileFourth title="Training Registration" />,
  //     },
  //   ],
  // },
  {
    path: "Admin/Account",
    element: <AdminSignin title="Admin Login" />,
  },
  {
    path: "Admin/ResetPassword/:uid/:token",
    element: <AdminResetPassword title="Reset Password" />,
  },
  {
    path: "Admin/ResetPassword",
    element: <AdmnFrstRstPwd title="Reset Password" />,
  },
  {
    path: "Account/ResetPassword/:uid/:token",
    element: <ResetPassword title="Reset Password" />,
  },
  {
    path: "Account/ResetPassword",
    element: <CandFrstRstPwd title="Reset Password" />,
  },
  {
    path: "Admin/Account/ForgotPassword",
    element: <AdminForgotPassword title="Reset Password" />,
  },
  {
    path: "Admin",
    element: <AdminLayout />,
    children: [
      { path: "/Admin", element: <Navigate to="/Admin/Account" replace /> },
      { path: "Dashboard", element: <Dashboard title="Dashboard" /> },
      {
        path: "Job-Postings",
        element: <AdminjobPosting title="Job Postings" />,
        children: [
          {
            path: "Add-Candidate",
            element: <AddNewCandidate title="Add Candidate" />,
          },
        ],
      },
      {
        path: "Add-Candidate",
        element: <AddNewCandidate title="Add Candidate" />,
      },

      {
        path: "IMocha-Report",
        element: <ImochaReport title="IMocha Results" />,
      },
      {
        path: "IMocha-Single-Test",
        element: <IMochaSingleTest title="IMocha Single Test Report" />,
      },

      {
        path: "Job-Openings",
        element: <AdminJobOpening title="Job Openings" />,
      },
      { path: "New-Add-Jobs", element: <AddJob title="Add Job" /> },
      { path: "Add-Jobs", element: <NewAddJob title="Add Job" /> },
      {
        path: "AppliedStatus",
        element: <AppliedStatus title="Applied Status" />,
      },
      {
        path: "AppliedStatus/:id",
        element: <AppliedStatus title="Applied Status" />,
      },
      {
        path: "TrainRegMem",
        element: <TrainRegMem title="Registered Members" />,
      },
      {
        path: "TrainRegMem/:id",
        element: <TrainRegMem title="Registered Members" />,
      },
      {
        path: "AppliedApplicants/:postId/:job_status/:job_apply_id",
        element: <AppliedApplicants title="Applied Applicants" />,
      },
      {
        path: "OldUserDetails/:jobId/:jobPostID",
        element: <UserDetails title="User Details" />,
      },
      {
        path: "UserDetails/:jobId/:jobPostID",
        element: <AppliedProfile title="User Details" />,
      },
      {
        path: "OldMemberSearch",
        element: <MemberSearch title="Member Profile" />,
      },
      {
        path: "MemberSearch",
        element: <MBSeach title="Member Profile" />,
      },
      {
        path: "AdminBulkEmail",
        element: <AdminBulkEmail title="Send Bulk Mail" />,
      },
      { path: "SingleEmail", element: <SingleEmail title="Send Mail" /> },
      {
        path: "AdminBulkSMS",
        element: <AdminBulkSMS title="Send Bulk SMS" />,
      },
      { path: "SingleSMS", element: <SingleSMS title="Send SMS" /> },
      { path: "OldCloudTests", element: <AdminCloudCert title="Cloud Test" /> },
      {
        path: "CloudTests",
        element: <AdminCloudCertifications title="Cloud Test" />,
      },
      {
        path: "Training-Classes",
        element: <AdminTrainingClass title="Training Classes" />,
      },
      {
        path: "EmailTemplate",
        element: <TemplateComponent title="Email Template" />,
      },
      {
        path: "OldNewEmailTemp",
        element: <NewEmailTemp title="New Email Template" />,
      },
      {
        path: "NewEmailTemp",
        element: <EmailTemplate title="New Email Template" />,
      },
      // { path: "AdminPanel", element: <AdminPanel title="Admin Panel" /> },
      { path: "SettingList", element: <SettingList title="SettingList" /> },
      { path: "Calendar", element: <Calendar title="Calendar" /> },
      {
        path: "AllAccessLevels",
        element: <AllAccessLevels title="All Access Levels" />,
      },
      {
        path: "AccessLevelAdmin",
        element: <AccessLevelAdmin title="Admin Access Level" />,
      },
      { path: "Recruiter", element: <AdminPanel title="Recruiters" /> },
      { path: "AddNewUser", element: <AddNewAdmin title="New User" /> },
      { path: "AddStatus", element: <AddStatus title="Add Status" /> },
      {
        path: "AddJobStatus",
        element: <AddJobStatus title="Add Job Status" />,
      },
      {
        path: "AddDepartment",
        element: <AddDepartment title="Add Department" />,
      },
      { path: "AddJobType", element: <AddJobType title="Add Job Type" /> },
      {
        path: "AddExperience",
        element: <AddMinExperience title="Add Experience" />,
      },
      {
        path: "AddLanguage",
        element: <AddLanguage title="Add Language" />,
      },
      {
        path: "AddShiftTiming",
        element: <AddShiftTiming title="Add Shift Timing" />,
      },
      {
        path: "AddSource",
        element: <AddSource title="Add Source" />,
      },
      {
        path: "AddJobLocation",
        element: <AddJobLocation title="Add Job Location" />,
      },
      // { path: "HrModule", element: <HrModule title="HR Module" /> },
      {
        path: "EditCandidateDetails",
        element: <EditCandidateDetails title="Edit Candidate Details" />,
      },
      {
        path: "OldOfferLetter",
        element: <OfferLetter title="Offer Letter" />,
      },
      {
        path: "OfferLetter",
        element: <CreateOfferLatter title="Offer Letter" />,
      },
      {
        path: "Reports",
        element: <AllReportsList title="Reports" />,
      },
      {
        path: "CandPipelineReport",
        element: <CandPipelnReport title="Candidate Pipeline Report" />,
      },
      {
        path: "CandSourceReport",
        element: <CandSourceReport title="Candidate Source Report" />,
      },
      {
        path: "TimetoHireReport",
        element: <TimetoHireReport title="Candidate Source Report" />,
      },
      {
        path: "BulkmailReport",
        element: <BulkmailReport title="Candidate Source Report" />,
      },
      {
        path: "ViewBulkmailHistory/:id",
        element: <ViewBulkmailHistory title="View Mail History" />,
      },
      {
        path: "NonSelectRprt",
        element: <NonSelectRprt title="Non-selection Report" />,
      },
      {
        path: "OffrAccptRatioRprt",
        element: <OffrAccptRatioRprt title="Offer Acceptance Report" />,
      },
      {
        path: "OfferTemplate",
        element: <OfferTemplate title="Offer Template" />,
      },
      {
        path: "OldNewOfferTemplate",
        element: <NewOfferTemplate title="New Offer Template" />,
      },
      {
        path: "NewOfferTemplate",
        element: <ModifyOfferLetter title="New Offer Template" />,
      },
      {
        path: "view_offer_admin/:id",
        element: <ViewOfferAdmin title="View Offer" />,
      },
    ],
  },
];

export default function RouterContainer() {
  const role = useBoundStore((state) => state.role);
  let routes = adminRoutes;

  useEffect(() => {
    // console.log("role", role);
  }, []);
  return useRoutes(routes);
}
